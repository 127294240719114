import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  DontDoItem,
  Link,
  List,
  PageHero,
  PageWithSubNav,
  PageNavigation,
  PageNavigationLink,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
  IconItemList,
  IconItem,
} from 'docComponents';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Icon"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icon" />

      <PageNavigation>
        <PageNavigationLink>Categories</PageNavigationLink>
        <PageNavigationLink>Labels</PageNavigationLink>
        <PageNavigationLink>Platform</PageNavigationLink>
      </PageNavigation>

      <Section title="Categories">
        {data.allIconsYaml.edges.map(list => {
          return (
            <Section key={list.node.title}>
              <SectionSubhead>{list.node.title}</SectionSubhead>
              <IconItemList>
                {list.node.icons.map(item => {
                  if (
                    item.react_name === 'uiexpandregionvertical' ||
                    item.react_name === 'uicollapseregionvertical'
                  ) {
                    return null;
                  }
                  return (
                    <IconItem
                      reactName={item.react_name}
                      displayName={item.name}
                      key={item.react_name}
                    />
                  );
                })}
              </IconItemList>
            </Section>
          );
        })}
      </Section>

      <Section title="Labels">
        <Paragraph>
          The icon label clearly states the action performed by the icon or icon
          button on hover.
        </Paragraph>
        <Paragraph>
          That said, don’t get it confused with the more detailed and
          instructional{' '}
          <Link href="/components/tooltip" isDesignCodeLink>
            tooltip
          </Link>
          . The latter allows for more text and includes a tail.
        </Paragraph>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="use a tooltip to simply name an icon."
            img="iconlabel-tooltip-dont"
          />
          <DontDoItem
            type="do"
            text="use an icon label when no additional instructions are needed."
            img="iconlabel-tooltip-do"
          />
        </DontDo>
        <DontDo gridLayout="2">
          <DontDoItem
            type="dont"
            text="make the label a complete sentence."
            img="iconlabel-sentence-dont"
          />
          <DontDoItem
            type="do"
            text="make it a single action and stick to UI label guidelines."
            img="iconlabel-sentence-do"
          />
        </DontDo>
        <Paragraph>
          <em>
            Abiding by{' '}
            <Link href="/words/content-elements/labels-non-label-text">
              UI label guidelines
            </Link>{' '}
            also means no icon label should have closing punctuation.
          </em>
        </Paragraph>
      </Section>

      <Section title="Platform">
        <PlatformTable footerMessage="No guidelines exist for Windows (yet).">
          <PlatformTableRow platform="web">
            <List>
              <li>Stick to the icons above.</li>
            </List>
          </PlatformTableRow>
          <PlatformTableRow platform="apple">
            <List>
              <li>Stick to the icons above.</li>
            </List>
          </PlatformTableRow>
          <PlatformTableRow platform="android">
            <List>
              <li>Stick to the icons above.</li>
            </List>
          </PlatformTableRow>
        </PlatformTable>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconDesign {
    allIconsYaml(sort: { order: ASC, fields: [title] }) {
      edges {
        node {
          title
          icons {
            name
            react_name
          }
        }
      }
    }
  }
`;
